import pageHeaderSticky from "./sticky-header";
import slider from "./swiper";
import Aos from "aos";
import burger from "./burger";
import smoothScroll from "./smoth-scroll";
import languageSwitcher from "./language-switcher";

var documentReady = function () {
    
    pageHeaderSticky();
    burger();
    slider();
    smoothScroll();
    languageSwitcher();

    Aos.init({
        once: true,
    });
};

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener("DOMContentLoaded", documentReady);
}
