export default function burger(){
    let headerLogo = document.getElementById('js-header-logo');
    let mainMenu = document.querySelector('.js-main-menu');
    let header = document.getElementById('js-page-header');
    let headerItems = document.querySelectorAll('.header-menu__item');
    let headerBurger = document.getElementById('js-header-burger');

    headerItems.forEach(element => {
        element.addEventListener('click', function(){
            headerLogo.classList.remove('is-active');
            mainMenu.classList.remove('-mobile');
            headerBurger.classList.remove('is-active');
        });
    });

    headerBurger.addEventListener('click', function () {
        headerBurger.classList.toggle('is-active');
        mainMenu.classList.toggle('-mobile');

        if(headerLogo.classList.contains('-other') && !headerLogo.classList.contains('is-active') && !header.classList.contains('is-scrolled')){
            headerLogo.classList.add('is-active');
        }else{
            headerLogo.classList.remove('is-active');
        }
    });
}
