export default function smoothScroll(){
    $(document).ready(function() {
        var lastScrollTop = 0; // Zmienna do przechowywania ostatniej pozycji scrolla
    
        // Function to handle smooth scrolling with an offset
        function smoothScrollTo($element, offset) {
            if ($element.length) {
                $('html, body').animate({
                    scrollTop: $element.offset().top + offset
                }, 800); // Smooth scroll animation duration
            }
        }
    
        // Function to determine the offset based on scroll direction
        function getScrollOffset() {
            var currentScrollTop = $(window).scrollTop();
            var offset = currentScrollTop > lastScrollTop ? -50 : -200;
            lastScrollTop = currentScrollTop;
            return offset;
        }
    
        // Check URL hash on page load and perform smooth scrolling
        if (window.location.hash) {
            var initialId = window.location.hash.substring(1);
            setTimeout(function() {
                smoothScrollTo($('#' + initialId), -200);
            }, 200); // Small delay to ensure the page is fully loaded
        }
    
        // Update lastScrollTop on every scroll event
        $(window).scroll(function() {
            lastScrollTop = $(window).scrollTop();
        });
    
        // Event listener for clicks on anchor links
        $('a[href^="#"]').click(function(e) {
            e.preventDefault(); // Prevent the default action
            var targetId = $(this).attr('href').substring(1);
            var $targetElement = $('#' + targetId);
    
            if ($targetElement.length) {
                // Scroll to the specified element
                var offset = getScrollOffset();
                smoothScrollTo($targetElement, offset);
    
                // Update the URL in the browser address bar
                if (window.location.hash.substring(1) === targetId) {
                    window.history.replaceState({}, '', '#' + targetId);
                } else {
                    window.history.pushState({}, '', '#' + targetId);
                }
            }
        });
    });
    
}