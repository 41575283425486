export default function languageSwitcher(){
    $('.js-language-switcher').on('click', function (event) { 
        event.stopPropagation();
        $(this).toggleClass('is-active');
    });

    $(document).on('click', function () {
        $('.js-language-switcher').removeClass('is-active');
    });

    $('.js-language-switcher').on('click', function (event) {
        event.stopPropagation();
    });
}
