export default function pageHeaderSticky() {

    const header = document.getElementById('js-page-header');
    const headerHeight = header.offsetHeight;
    let headerMenu = document.getElementById('menu-menu-glowne');

    if (!headerMenu) {
        headerMenu = document.getElementById('menu-menu-glowne-en');
    }
    if (!headerMenu) {
        headerMenu = document.getElementById('menu-menu-glowne-de');
    }
    
    const headerLogo = document.getElementById('js-header-logo');
    const headerBurger = document.getElementById('js-header-burger');
    let scrollDirection = null;
    let lastScrollTop = 0;

   window.onload = function() {
        if (header) {
            var headerTop = header.getBoundingClientRect().top + window.scrollY;

            console.log(headerTop);
            if (headerTop >= 80) {
                header.classList.add("is-fixed");
            }
        }

    };

    document.addEventListener('scroll', event => {
        var st = window.pageYOffset || document.documentElement.scrollTop; 
    
        if (!header.classList.contains('is-active')) {
            //scroll strength

            if (Math.abs(st - lastScrollTop) < 15){
                return;
            }
            
            //scroll direction
            if (st > lastScrollTop){
                header.classList.remove('is-sticky')
                if(headerMenu.classList.contains('-other')){
                    headerMenu.classList.remove('is-active');
                }
                if(headerLogo.classList.contains('-other')){
                    headerLogo.classList.remove('is-active');
                }
                removeDisabled()
            } else {
                header.classList.add('is-sticky')
                if(headerMenu.classList.contains('-other')){
                    headerMenu.classList.add('is-active');
                }
                if(headerLogo.classList.contains('-other')){
                    headerLogo.classList.add('is-active');
                }
                removeDisabled()
            }

            //scrolled
            if (window.scrollY > (headerHeight + 20)) {
                header.classList.add('is-scrolled')
                if(headerMenu.classList.contains('-other')){
                    headerMenu.classList.add('is-active');
                }
                if(headerLogo.classList.contains('-other')){
                    headerLogo.classList.add('is-active');
                    headerLogo.classList.add('is-scrolled');
                }
                if(headerBurger.classList.contains('-other')){
                    headerBurger.classList.add('is-scrolled');
                }
                removeDisabled()
            }
            else {
                header.classList.remove('is-scrolled')
                if(headerMenu.classList.contains('-other')){
                    headerMenu.classList.remove('is-active');
                }
                if(headerLogo.classList.contains('-other')){
                    headerLogo.classList.remove('is-active');
                    headerLogo.classList.remove('is-scrolled');
                }
                if(headerBurger.classList.contains('-other')){
                    headerBurger.classList.remove('is-scrolled');
                }
                removeDisabled()
            }

            //fixed
            if (window.scrollY > 90) {
                header.classList.add('is-fixed')
            }
            else if (window.scrollY == 0) {
                header.classList.remove('is-fixed')
            }
        }
        if (window.scrollY == 0) {
            header.classList.remove('is-fixed', 'is-scrolled', 'is-sticky')
        }

        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    })

    function removeDisabled() {
        let timeoutHandle = null;
        if (timeoutHandle) {
            window.clearTimeout(timeoutHandle);
            let timeoutHandle = null;
        }
        header.classList.add('is-disabled')
        timeoutHandle = window.setTimeout(function(){
            header.classList.remove('is-disabled')
        }, 200)
    }
}
