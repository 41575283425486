import Swiper from "swiper";
import { Pagination, Scrollbar } from 'swiper/modules';

export default function slider(){

    const sliderSelector = document.querySelector('.js-trusted-us-slider');

    const swiper = new Swiper(sliderSelector, {
        modules: [Pagination, Scrollbar],
        spaceBetween: 24,
        pagination:{
            el: '.swiper-pagination',
            clickable: true
        },
        breakpoints: {
            1500:{
                slidesPerView: 7
            },
            1200: {
                slidesPerView: 5,
            },
            768: {
                slidesPerView: 4,
            },
            320: {
                slidesPerView: 2,
            },
        },
    });
}
